/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAmbiance = /* GraphQL */ `
  query GetAmbiance($id: ID!) {
    getAmbiance(id: $id) {
      id
      url
      createdAt
      updatedAt
    }
  }
`;
export const listAmbiances = /* GraphQL */ `
  query ListAmbiances(
    $filter: ModelAmbianceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmbiances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAmbiance = /* GraphQL */ `
  query GetUserAmbiance($id: ID!) {
    getUserAmbiance(id: $id) {
      id
      userId
      ambianceId
      nom
      image
      User {
        id
        user
        email
        image
        createdAt
        updatedAt
      }
      Ambiance {
        id
        url
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserAmbiances = /* GraphQL */ `
  query ListUserAmbiances(
    $filter: ModelUserAmbianceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAmbiances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        ambianceId
        nom
        image
        User {
          id
          user
          email
          image
          createdAt
          updatedAt
        }
        Ambiance {
          id
          url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user
      email
      image
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        email
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
