
import './App.scss';
import awsconfig from './aws-exports';
import { AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import React, { useState, useEffect } from 'react';
import Amplify, { API, graphqlOperation,Storage , Auth } from 'aws-amplify';
import {listUsers,listUserAmbiances} from './graphql/queries';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';



Amplify.configure(awsconfig);

function App() {
const [windowDimension, setWindowDimension] = useState(null);
const [user, setUser] = useState(null);
const [ambiances, setAmbiances] = useState(null);
const [images, setImages] = useState(null);
const [imageUser, setImageUser] = useState(null);
const isMobile = windowDimension >= 640;
const [toggle, setToggle] = useState(false);
const email=Auth.user.attributes.email;

useEffect(() => {
  fetchUser();
  setWindowDimension(window.innerWidth);
}, []);


useEffect(() => {
  function handleResize() {
    setWindowDimension(window.innerWidth);


  }

  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
}, []);


const fetchUser = async ()=> {
  const userid = await API.graphql(graphqlOperation(listUsers,{filter: { email: {eq:  email}}}));
  const ambiance = await API.graphql(graphqlOperation(listUserAmbiances,{filter: { userId: {eq:  userid.data.listUsers.items[0].id}}}));
  console.log(ambiance.data.listUserAmbiances.items)
  setAmbiances(ambiance.data.listUserAmbiances.items)
  setUser(userid.data.listUsers.items[0])
  const imagelist=[]
  for (var i=0; i < ambiance.data.listUserAmbiances.items.length; i++) {
    const imagetmp =await Storage.get(ambiance.data.listUserAmbiances.items[i].image, { expires: 500 });
    imagelist.push("url("+imagetmp+")")
} 
  setImages(imagelist)
  const imageusr =await Storage.get(userid.data.listUsers.items[0].image, { expires: 500 });
  setImageUser("url("+imageusr+")")
  console.log(imageusr)
}

const MultiPlayer = ({ ambiances,images }) => {
  return (
    <div>
      {images?ambiances.map((ambiance, i) => (
        <div className="Player" key={i} style={{backgroundImage: images[i] }} >
          <div className="PlayButton" >
          <AudioPlayer
          src={ambiance.Ambiance.url}	
          showJumpControls = {false}
          showDownloadProgress= {false}
          showFilledProgress= {false}
          customProgressBarSection= {[]}
          customAdditionalControls= {[]}
          layout='horizontal-reverse'
          customVolumeControls={[]} 
          onPlay={()=>(setToggle(true))}
          onPause={()=>(setToggle(false))}
          
        />
        </div>
        <div>
        <div className="Name">{"  "+ambiance.nom+"  "}</div>
        </div>
      </div>
      )):""}
    </div>
  )
}



const UserIHM = () => {


  return (
    
    <div className="Corps" >
    {imageUser?<div className="Photoperso" style={{backgroundImage: imageUser }}> </div>:""}
	              <h3> </h3>
              <div className="Ambiance">
              {toggle?<MultiPlayer
        ambiances={ambiances} images={images}
      />
:<MultiPlayer
ambiances={ambiances} images={images}
/>}
           </div>
               </div>
  );
};


  return (
    <div className="App">
    <header className="App-header">
    <div className="NameM">{user!=null? '':''}</div><div className="SignoutM"><AmplifySignOut /></div></header>



<UserIHM/>
<footer > <div className="App-footer">Power by Broder for Canaille Chic - Support:+33745352454 -</div></footer>
</div>


  );
}

export default withAuthenticator(App);
